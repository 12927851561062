import React from "react";
import Layout from '../components/layout';
import { Link } from "gatsby";
import FacebookImg from "../assets/facebook.png";
import GithubImg from "../assets/github.png";
import GmailImg from "../assets/gmail.png";
import GoodreadsImg from "../assets/goodreads.png";
import LichessImg from "../assets/lichess.png";
import LinkedinImg from "../assets/linkedin.png";
import StravaImg from "../assets/strava.png";
import TwitterImg from "../assets/twitter.png";
import NoPoliticalAgendaImg from "../assets/no_political_agenda.jpg";


export default function Home() {
    return (
        <Layout title="Home">
              <h1>
                Home
              </h1>
              <p>
                <img src={NoPoliticalAgendaImg} alt="" style={{ float: "left", marginRight: "1em"}} />Hoi! Ik ben Henk te Sligte, een developer en architect met een passie voor software. Daarnaast hou ik van hardlopen, schaken, lezen en schrijven. Sinds een jaar of twee snuffel ik ook nog aan cabaret, maar door corona staat dat even stil. Je kunt hier mijn contactgegevens vinden, en een verzameling korte verhalen die ik schrijf voor een cursus.
              </p>
              <p>Wil je met me in contact komen? Gebruik dan &eacute;&eacute;n van de links hieronder. </p>
              <p className="social-icons">
                <span>
                    <a href="https://facebook.com/henk.tesligte" target="_blank" rel="noreferrer">
                        <img src={FacebookImg} alt="" />
                    </a>
                </span>
                <span>
                    <a href="https://twitter.com/henkts/" target="_blank" rel="noreferrer">
                        <img src={TwitterImg} alt="" />
                    </a>
                </span>
                <span>
                    <a href="https://github.com/htesligte" target="_blank" rel="noreferrer">
                        <img src={GithubImg} alt="" />
                    </a>
                </span>
                <span>
                    <a href="https://www.linkedin.com/in/henk-te-sligte-1a63b6105/" target="_blank" rel="noreferrer">
                        <img src={LinkedinImg} alt="" />
                    </a>
                </span>
                <span>
                    <a href="https://www.strava.com/athletes/24314155" target="_blank" rel="noreferrer">
                        <img src={StravaImg} alt="" />
                    </a>
                </span>
                <span>
                    <a href="https://www.goodreads.com/user/show/49009857-henk-te-sligte" target="_blank" rel="noreferrer">
                        <img src={GoodreadsImg} alt="" />
                    </a>
                </span>
                <span>
                    <a href="https://lichess.org/@/htesligte" target="_blank" rel="noreferrer"> 
                        <img src={LichessImg} alt="" />
                    </a>
                </span>
                <span>
                    <Link to="/contact">
                        <img src={GmailImg} alt="" />
                    </Link>
                </span>
              </p>
        </Layout>        
    )
}